document.addEventListener("turbolinks:load", function () {
  var operationsDropdown = document
    .getElementById("operations-dropdown")
    .querySelector("select");
  var groupDropdown = document.getElementById("group-dropdown");
  var vendorDropdown = document.getElementById("vendor-dropdown");
  var poFileUpload = document.getElementById("po-file-upload");
  var vendorFileUpload = document.getElementById("vendor-file-upload");
  var poLocation = document.getElementById("po-location");
  var vendorDropdownChoice = vendorDropdown.querySelector("select");

  function resetDisplay() {
    groupDropdown.style.display = "none";
    vendorDropdown.style.display = "none";
    poFileUpload.style.display = "none";
    poLocation.style.display = "none";
    vendorFileUpload.style.display = "none";
  }

  function handleVendorDropdownChange() {
    var selectedVendor = vendorDropdownChoice.value;
    console.log(selectedVendor);

    if (selectedVendor === "Manual") {
      vendorFileUpload.style.display = "block";
    } else {
      vendorFileUpload.style.display = "none";
    }
  }

  operationsDropdown.addEventListener("change", function () {
    var selectedOperation = operationsDropdown.value;
    console.log("Event Changed", selectedOperation);

    resetDisplay();

    if (selectedOperation === "create" || selectedOperation === "push") {
      groupDropdown.style.display = "block";
      vendorDropdown.style.display = "block";
      poLocation.style.display =
        selectedOperation === "push" ? "block" : "none";

      vendorDropdownChoice.removeEventListener(
        "change",
        handleVendorDropdownChange
      );
      vendorDropdownChoice.addEventListener(
        "change",
        handleVendorDropdownChange
      );
    } else if (selectedOperation === "manual") {
      console.log("Mannual")
      poFileUpload.style.display = "block";
      groupDropdown.style.display = "block";
    }
  });

  // Initialize state on page load
  operationsDropdown.dispatchEvent(new Event("change"));
});
